<template>
  <div>
    <a-affix :offset-top="0">
      <a-page-header class="header-affix mb-4">
        <a-form>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-md-2">
                  <a-input v-model:value="searchTitle" placeholder="Поиск по названию" @change="findByTitle" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-input v-model:value="searchMessage" placeholder="Поиск по тексту" @change="findByMessage" allow-clear />
                </div>
                <div class="col-md-8 text-right">
                  <a-button class="w-100 text-right" @click="clearFilters" shape="round" type="link">
                    <template #icon><CloseCircleOutlined /></template>
                    Сбросить фильтр
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </a-page-header>
    </a-affix>
    <vb-create-notification @reload="reload" />
    <vb-edit-notification
      v-if="modalVisible"
      :notification-id="notificationId"
      @reload="reload"
      @hide-modal="hideModal" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      :scroll="{ x: 1300 }"
      row-key="id"
    >
      <template #img="{ record }">
        <div
          v-if="record.img"
          :style="getBackground(record.img)"
          class="notification-img"
        ></div>
      </template>
      <template #inform="{ record }">
        <div class="row px-1">
          <div class="d-flex" style="gap: 20px">
            <a-card size="small" :bordered="false" style="line-height: 18px">
              <div class="d-flex justify-content-between align-items-center" style="gap: 10px">
                <div class="d-flex flex-column">
                  <span>Рассылка</span>
                  <span>по официантам</span>
                </div>
                <CheckCircleTwoTone style="font-size: 20px" two-tone-color="#52c41a" color="green" v-if="record.inform_employees" />
                <CloseCircleTwoTone style="font-size: 20px" two-tone-color="#eb2f96" v-else />
              </div>
            </a-card>
            <a-card size="small" :bordered="false" style="line-height: 18px">
              <div class="d-flex justify-content-between align-items-center" style="gap: 10px">
                <div class="d-flex flex-column">
                  <span>Рассылка</span>
                  <span>по группам</span>
                </div>
                <CheckCircleTwoTone style="font-size: 20px" two-tone-color="#52c41a" color="green" v-if="record.inform_groups" />
                <CloseCircleTwoTone style="font-size: 20px" two-tone-color="#eb2f96" v-else />
              </div>
            </a-card>
            <a-card type="inner" :bordered="false" size="small" style="line-height: 36px">Всего получателей: {{ record['overall_num'] }}</a-card>
          </div>
        </div>
      </template>
      <template #informed="{ record }">
        <div class="row px-1">
          <a-card type="inner" :bordered="false" size="small" style="line-height: 36px">Всего отправлено: {{ record['informed_num'] }}</a-card>
        </div>
      </template>
      <template #time="{ record }">
        <div class="row px-1">
          <a-card v-if="record['start_time']" :bordered="false" size="small" style="line-height: 20px; float: left">
            <div class="d-flex flex-column" style="gap: 4px">
              <div class="d-flex" style="gap: 6px; line-height: 16px">
                <ClockCircleTwoTone />
                <span>Начало: {{ record['start_time'] ?? 'Не задано' }}</span>
              </div>
              <span class="d-flex" style="gap: 6px; line-height: 16px">
                <ClockCircleTwoTone />
                <span>Конец: {{ record['end_time'] ?? 'Не задано' }}</span>
              </span>
            </div>
          </a-card>
          <div v-else>
            <a-card size="small" :bordered="false" style="line-height: 36px">
              <a-typography-text disabled>Не задано</a-typography-text>
            </a-card>
          </div>
        </div>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top">
            <template #title>Отправить</template>
            <a @click="showSendModal(record.id)" class="mx-1"><i class="fe fe-send"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="showModal(record.id)" class="mx-1"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top">
            <template #title>Удалить</template>
            <a @click="showDeleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      :title="false"
      v-model:visible="sendModalVisible"
      :confirm-loading="sendLoading"
      :closable="false"
      :footer="false"
      @cancel="hideSendModal"
    >
      <a-typography-title :level="5">Вы уверены что хотите отправить рассылку?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="send" :loading="sendLoading" type="primary" class="float-right">Отправить</a-button>
        <a-button @click="hideSendModal" type="secondary" class="float-right mx-2">Отменить</a-button>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="hideDeleteModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить рассылку?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteNotification" :loading="deleteLoading" type="primary" class="float-right">Да</a-button>
        <a-button @click="hideDeleteModal" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { message, notification } from 'ant-design-vue'
import apiClient from "@/services/axios";
import {useRoute, useRouter} from "vue-router";
import VbEditNotification from "@/views/notification/edit";
import { onMounted, reactive, ref, toRefs, watch } from "vue";
import VbCreateNotification from "@/views/notification/create";
import { CloseCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ClockCircleTwoTone } from "@ant-design/icons-vue";

let
  timer = null,
  stores = ref([]),
  brands = ref([]),
  searchTitle = ref(''),
  searchMessage = ref(''),
  loading = ref(false),
  sendLoading = ref(false),
  deleteId = ref(0),
  deleteLoading = ref(false),
  deleteModal = ref(false),
  modalVisible = ref(false),
  sendModalVisible = ref(false),
  dataSource = ref([]),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    title: '',
    message: ''
  },
  queryParams = reactive({...initialParams}),
  total = ref(0),
  notificationId = ref(0),
  sendNotificationId = ref(0);

const
  columns = [
    {
      title: 'Название',
      sorter: true,
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      width: 200,
    },
    {
      title: 'Картинка',
      dataIndex: 'img',
      sorter: true,
      key: 'img',
      slots: { customRender: 'img' },
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Получатели',
      dataIndex: 'inform',
      key: 'inform',
      width: 550,
      slots: { customRender: 'inform' },
    },
    {
      title: 'Отправлено',
      dataIndex: 'informed_num',
      key: 'informed_num',
      width: 250,
      slots: { customRender: 'informed' },
    },
    {
      title: 'Время отправки',
      dataIndex: 'time',
      key: 'time',
      width: 300,
      slots: { customRender: 'time' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      width: 180,
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      width: 180,
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
      fixed: 'right',
      width: 100,
    },
  ],
  findByTitle = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ title: e.target.value })
    }, 800);
  },
  findByMessage = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ message: e.target.value })
    }, 800);
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  showModal = (id) => {
    notificationId.value = id
    modalVisible.value = true;
  },
  showSendModal = (id) => {
    sendNotificationId.value = id
    sendModalVisible.value = true
  },
  hideSendModal = () => {
    sendNotificationId.value = 0
    sendModalVisible.value = false
  },
  send = () => {
    sendLoading.value = true
    apiClient.post('notification/send?id=' + sendNotificationId.value).then(({ data }) => {
      if (data?.data?.success) {
        notification.success({
          message: 'Успешно',
          description: data?.data?.message,
        })
      } else {
        notification.error({
          message: 'Ошибка',
          description: data?.data?.message,
        })
      }
    }).finally(() => {
      sendLoading.value = false
      hideSendModal()
    })
  },
  route = useRoute(),
  router = useRouter(),
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    searchTitle.value = ''
    searchMessage.value = ''

    reload()
  },
  hideDeleteModal = () => {
    deleteLoading.value = false
    deleteModal.value = false
    deleteId.value = 0
  },
  showDeleteModal = id => {
    deleteModal.value = true
    deleteId.value = id
  },
  deleteNotification = () => {
    deleteLoading.value = true
    return apiClient.delete(`notification/delete?id=${deleteId.value}`).then(response => {
      if (response) {
        message.success('Рассылка успешно удалена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      hideDeleteModal()
      reload()
    });
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get(`/notification`, { params }).then(({ data }) => {
      dataSource.value = data.data.items
      stores.value = data.data.stores
      brands.value = data.data.brands
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  getBackground = (url) => {
    return `background-image: url('${url}')`;
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})
watch(queryParams, () => {
  router.push({
    name: 'notification',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
});

const query = {...toRefs(queryParams)}

</script>

<style scoped>
@import "./index.css";
</style>
