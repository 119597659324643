<template>
  <a-modal
    title="Редактировать рассылку"
    v-model:visible="modalVisible"
    :confirm-loading="confirmLoading"
    :closable="false"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="notification" layout="vertical">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-upload
          name="img"
          list-type="picture-card"
          class="logo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="beforeUpload"
          @change="uploadImage"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Картинка</div>
          </div>
        </a-upload>
        <div class="mb-4 text-center" v-if="imageUrl">
          <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить картинку</a-button>
        </div>
        <a-form-item label="Название рассылки">
          <a-input
            ref="notificationTitle"
            v-model:value="notification.title"
            placeholder="Введите название"
          />
        </a-form-item>
        <a-form-item label="Сообщение">
          <ckeditor
            :editor="editor"
            v-model="notification.message"
            :config="editorConfig"
          ></ckeditor>
        </a-form-item>
        <a-card size="small" class="cursor-pointer mb-4" @click="toggleEmployees">
          <div class="d-flex justify-content-between">
            <div>Рассылка по официантам</div>
            <a-switch
              checked-children="Вкл"
              un-checked-children="Выкл"
              v-model:checked="notification.inform_employees"
            />
          </div>
        </a-card>
        <a-card size="small" class="cursor-pointer" @click="toggleGroups">
          <div class="d-flex justify-content-between">
            <div>Рассылка по группам</div>
            <a-switch
              checked-children="Вкл"
              un-checked-children="Выкл"
              v-model:checked="notification.inform_groups"
            />
          </div>
        </a-card>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import Notification from "@/models/Notification";
import {ref, toRaw, reactive} from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getHeaders } from '@/helpers/header'
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { getBase64 } from '@/helpers/base64'
import { message } from 'ant-design-vue'

const props = defineProps({
  notificationId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload'])

let imageUrl = ref(''),
  loading = ref(false),
  fetching = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  notificationTitle = ref("notificationTitle"),
  storesList = ref([]),
  editor = ref(ClassicEditor),
  editorConfig = ref({
  }),
  notification = reactive(new Notification({}));

const
  toggleEmployees = (e) => {
    let switcher = false
    e.target.classList.forEach(classname => {
      if (['ant-switch', 'ant-switch-inner'].includes(classname))
        switcher = true
    })
    if (switcher) return
    notification.inform_employees = !notification.inform_employees
  },
  toggleGroups = (e) => {
    let switcher = false
    e.target.classList.forEach(classname => {
      if (['ant-switch', 'ant-switch-inner'].includes(classname))
        switcher = true
    })
    if (switcher) return
    notification.inform_groups = !notification.inform_groups
  },
  resetForm = () => {
    Object.assign(notification, new Notification({}))
    modalVisible.value = false;
    emit('hide-modal')
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/notification/upload'
  },
  removeImage = () => {
    imageUrl.value = ''
    notification.img = ''

    confirmLoading.value = true;
    apiClient.put(`/notification/update?id=${props.notificationId}`, { img: '' })
      .then(() => {
        // console.log(response)
      }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false
      emit('reload')
    })
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      notification.img = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(notification)
    model.inform_employees = model.inform_employees ? 1 : 0
    model.inform_groups = model.inform_groups ? 1 : 0

    return apiClient
      .put(`/notification/update?id=${props.notificationId}`, model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/store/fetch`).then(({data}) => {
  storesList.value = data.data
})
apiClient.get(`/notification/view?id=${props.notificationId}`).then(({ data }) => {
  if (data) {
    imageUrl.value = data.data.notification?.img
    let model = new Notification(data.data.notification)
    Object.assign(notification, model)
    notification.inform_employees = notification.inform_employees === 1
    notification.inform_groups = notification.inform_groups === 1
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  fetching.value = false;
  // nextTick(() => notificationTitle.value?.focus());
})
</script>

<style scoped>
@import "./edit.css";
</style>
